// DEPRECATION: This component is DEPRECATED and its not recommended for use.
// To use modals: import asModal from 'components/modals/asModal'

import React, { useState, useContext } from 'react';
import styled from '@emotion/styled/macro';
import { Col, Row } from 'react-styled-flexboxgrid';
import { ClipLoader } from 'react-spinners';
import { FormProvider } from 'react-hook-form';
import { DateTime } from 'luxon';
import validator from 'validator';

import { pluralize } from 'utils/helpers';

import {
  Modal as ChakraModal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button as ChakraButton,
} from '@chakra-ui/react';
import TrackSearch from 'components/TrackSearch/track-search';
import Modal, { ModalHeaderWrapper } from 'features/Modal/modal';
import Icon from 'components/Icon/icon';
import Tooltip from 'components/Tooltip/tooltip';

import FieldLabel from 'components/FieldLabel/field-label';
import Button from 'components/Button/button';
import SummaryItem from 'components/SummaryItem/summary-item';
import InfoBadge from 'components/InfoBadge/info-badge';

import TextInput from 'features/TextInput/text-input';

import { useGlobalData } from '../global-data';
import { API_STATUS_KEYS } from 'constants/constants';
import { LANGUAGES2 } from 'constants/dictionaries';
import { territories as dictTerritories } from 'constants/territories';

import FallbackCoverArt from 'assets/fallback-cover-art.png';

import { ButtonCol, ErrorCol, CenterBlock, SpacedUppercaseText, Paragraph14, FormGridRow } from 'styles';
import ReleaseWarningModal from './release-warning-modal';
import VideoReleaseWarningModal from './video-release-warning-modal';
import ConfirmIsrcModal from './confirm-isrc-modal';
import ConfirmSplitChangesModal from './confirm-split-changes';

import HEAP from '../../constants/HEAP.gen.json';
import useCurrentUser from 'data-client/use-current-user';

import _debounce from 'lodash/debounce';

const AlbumArt = styled.img`
  border-radius: 2px;
  padding-bottom: 0.5rem;
`;

const SummaryTracksScrollContainer = styled.div`
  padding-top: 3px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: 0;
  height: 100%;
  margin-left: 2rem;
  padding: 0;

  i {
    height: 1.5rem;
    width: 1.5rem;
  }
`;
const TooltipWrapper = styled(Tooltip)`
  margin: 0;
`;

const BottomRow = styled(Row)`
  height: auto !important;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoBadgeWrapper = styled(InfoBadge)``;

const SummarySectionHeader = styled.div`
  border-bottom: 1px dashed ${props => props.theme.colors.brand.ghost};
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  span {
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
  }
`;

const SummarySpacer = styled.div`
  height: 0.5rem;
`;

const SummarySectionTitle = styled.h4`
  align-items: center;
  display: flex;
`;

const HeaderText = styled(SpacedUppercaseText)`
  color: ${props => props.theme.colors.positive};
  margin-left: 0.5rem;

  ${props =>
    props.invalid &&
    `
    color: ${props.theme.colors.negative};
  `}
`;

export const ModalsContext = React.createContext();
export const useModals = () => useContext(ModalsContext);
export const ModalsProvider = ({ children }) => {
  const [modalState, setModalState] = useState({});
  const [selectedModalId, setSelectedModalId] = useState(null);

  const { dsps, dspsStatus } = useGlobalData();
  const [dbUser] = useCurrentUser();

  const showModal = (type, id = 0) => {
    updateModalState(type, id, { isShowing: true });
    setSelectedModalId(id);
  };

  const hideModal = (type, id = 0) => {
    updateModalState(type, id, { isShowing: false });
    setSelectedModalId(null);
  };

  const updateModalState = (type, idOrData, data) => {
    if (data) updateModalStateById(type, idOrData, data);
    else updateModalStateById(type, 0, idOrData);
  };

  const updateModalStateById = (type, id, data) =>
    setModalState(prevState => {
      const newState = { ...prevState };
      if (!newState[type]) newState[type] = {};
      if (!newState[type][id]) newState[type][id] = {};
      newState[type][id] = { ...newState[type][id], ...data };
      return newState;
    });

  const noBackend = modalState['noBackend'] ? modalState['noBackend'][selectedModalId] : null;
  const releaseSummary = modalState['releaseSummary'] ? modalState['releaseSummary'][selectedModalId] : null;
  const searchISRC = modalState['searchISRC'] ? modalState['searchISRC'][selectedModalId] : null;
  const duplicateRelease = modalState['duplicateRelease'] ? modalState['duplicateRelease'][selectedModalId] : null;
  const addPayee = modalState['addPayee'] ? modalState['addPayee'][selectedModalId] : null;
  const releaseDateTooSoon = modalState['WARNING_RELEASE_DATE_TOO_SOON']
    ? modalState['WARNING_RELEASE_DATE_TOO_SOON'][selectedModalId]
    : null;
  const videoReleaseDateTooSoon = modalState['WARNING_VIDEO_RELEASE_DATE_TOO_SOON']
    ? modalState['WARNING_VIDEO_RELEASE_DATE_TOO_SOON'][selectedModalId]
    : null;
  const confirmIsrc = modalState['confirmIsrc'] ? modalState['confirmIsrc'][selectedModalId] : null;
  const confirmSplitChanges = modalState['confirmSplitChanges']
    ? modalState['confirmSplitChanges'][selectedModalId]
    : null;
  // Modal-sepecifc vars

  const summaryInstantGrat = releaseSummary
    ? releaseSummary.releaseFormObject.track_instant_grat_dates
      .map((date, i) => (date ? `${i + 1}: ${date}` : null))
      .filter(date => !!date)
    : [];

  return (
    <ModalsContext.Provider
      value={{
        modalState,
        showModal,
        hideModal,
        updateModalState,
      }}>
      {children}

      {noBackend && (
        <Modal
          header={
            <ModalHeaderWrapper>
              <h4>Oops. Something went wrong.</h4>
            </ModalHeaderWrapper>
          }
          config={{ style: { height: '240px', width: '500px' } }}
          isShowing={noBackend.isShowing}
          persistent>
          <Row>
            <Col xs={12}>
              <Paragraph14 noPad>We&apos;re having trouble connecting.</Paragraph14>
              <Paragraph14>
                If this keeps happening, please reach out to{' '}
                <a href="mailto:yourfriends@venice.tech">yourfriends@Venice.tech</a>
              </Paragraph14>
            </Col>
          </Row>
        </Modal>
      )}

      {releaseSummary && (
        <Modal
          showClose={false}
          header={
            <ModalHeaderWrapper>
              <BottomRow>
                <Col xs={5}>
                  <h3>Release Summary</h3>
                </Col>
                {!releaseSummary.isModalPreviewOnly ? (
                  <>
                    <ErrorCol xs={3}>{releaseSummary.publishReleaseStatus[API_STATUS_KEYS.ERROR]}</ErrorCol>
                    <Col xs={1}></Col>
                    <ButtonCol xs={3}>
                      <Button
                        text="Submit for Distribution"
                        loading={
                          releaseSummary.saveReleaseStatus[API_STATUS_KEYS.IN_PROGRESS] ||
                          releaseSummary.publishReleaseStatus[API_STATUS_KEYS.IN_PROGRESS]
                        }
                        disabled={
                          (!releaseSummary.publishReleaseStatus[API_STATUS_KEYS.IN_PROGRESS] &&
                            releaseSummary.publishReleaseStatus[API_STATUS_KEYS.COMPLETE]) ||
                          !!(
                            Object.keys(releaseSummary.releaseSubmissionValidity.setup).length ||
                            releaseSummary.releaseSubmissionValidity.tracklist.errors ||
                            Object.keys(releaseSummary.releaseSubmissionValidity.releaseDetails).length
                          ) ||
                          dbUser?.subscription?.isInGracePeriod ||
                          dbUser?.subscription?.isInTrial
                        }
                        onClick={_debounce(() => releaseSummary.submitForDistributionClickedHandler(), 5000, {
                          leading: true,
                        })}
                        heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_BUTTON_SUBMIT}
                      />
                      <CloseButton onClick={() => releaseSummary.releaseSummaryCloseHandler()}>
                        <Icon type="exit" />
                      </CloseButton>
                    </ButtonCol>
                  </>
                ) : (
                  <ButtonCol xs={6}>
                    <CloseButton onClick={() => releaseSummary.releaseSummaryCloseHandler()}>
                      <Icon type="exit" />
                    </CloseButton>
                  </ButtonCol>
                )}
              </BottomRow>
            </ModalHeaderWrapper>
          }
          isShowing={releaseSummary.isShowing}
          config={{
            style: {
              height: '95%',
              width: '90%',
              maxWidth: '866px',
            },
          }}
          onClose={() => releaseSummary.releaseSummaryCloseHandler()}>
          {!releaseSummary.releaseFormObject || releaseSummary.saveReleaseStatus[API_STATUS_KEYS.IN_PROGRESS] ? (
            <CenterBlock>
              <ClipLoader />
            </CenterBlock>
          ) : (
            <Row>
              <Col xs={12} md={8}>
                <SummarySectionHeader>
                  <SummarySectionTitle>
                    <InfoBadgeWrapper incomplete={Object.keys(releaseSummary.releaseSubmissionValidity.setup).length} />
                    <HeaderText invalid={Object.keys(releaseSummary.releaseSubmissionValidity.setup).length}>
                      Setup Release
                    </HeaderText>
                  </SummarySectionTitle>
                </SummarySectionHeader>
                <Row>
                  <Col xs={3}>
                    <div style={{ width: '100%' }}>
                      {releaseSummary.releaseImage ? (
                        <AlbumArt src={releaseSummary.releaseImage} alt="Album artwork" />
                      ) : (
                        <AlbumArt src={FallbackCoverArt} alt="Placeholder album artwork" />
                      )}
                    </div>
                    <SummaryItem
                      primaryText="Edit Image"
                      error={releaseSummary.releaseSubmissionValidity.setup['image']}
                      onClick={() => releaseSummary.releaseSummaryEditClickedHandler('UploadCoverArt')}
                      readonly={!releaseSummary.cardsAccessible['UploadCoverArt']}
                    />
                  </Col>
                  <Col xs={9}>
                    <SummaryItem
                      primaryText="Release Title:"
                      error={releaseSummary.releaseSubmissionValidity.setup['title']}
                      secondaryText={releaseSummary.releaseFormObject.title}
                      onClick={() => releaseSummary.releaseSummaryEditClickedHandler('CoreDetails')}
                      readonly={!releaseSummary.cardsAccessible['CoreDetails']}
                    />
                    {releaseSummary.releaseFormObject.sub_title && (
                      <SummaryItem
                        primaryText="Release Version:"
                        error={releaseSummary.releaseSubmissionValidity.setup['sub_title']}
                        secondaryText={releaseSummary.releaseFormObject?.sub_title}
                        onClick={() => releaseSummary.releaseSummaryEditClickedHandler('CoreDetails')}
                        readonly={!releaseSummary.cardsAccessible['CoreDetails']}
                      />
                    )}
                    {!releaseSummary.releaseFormObject._meta.isCompilation && (
                      <SummaryItem
                        primaryText="Release Artists:"
                        error={releaseSummary.releaseSubmissionValidity.setup['artists']}
                        parties={releaseSummary.releaseFormObject?.artists || []}
                        onClick={() => releaseSummary.releaseSummaryEditClickedHandler('ReleaseArtists')}
                        readonly={!releaseSummary.cardsAccessible['ReleaseArtists']}
                      />
                    )}
                    <SummaryItem
                      primaryText="Release Type:"
                      secondaryText={
                        releaseSummary.releaseFormObject?._meta?.releaseType === 'VideoSingle'
                          ? 'Video Single'
                          : releaseSummary.releaseFormObject?._meta?.releaseType
                      }
                      readonly
                    />
                  </Col>
                </Row>
                <SummarySpacer />
                <Row>
                  <Col xs={9} xsOffset={3}>
                    <SummaryItem
                      primaryText="UPC:"
                      secondaryText={releaseSummary.releaseFormObject.asset_release_upc}
                      onClick={() => releaseSummary.releaseSummaryEditClickedHandler('ReleaseDetails')}
                      readonly={!releaseSummary.cardsAccessible['ReleaseDetails']}
                    />
                    <SummaryItem
                      primaryText="Display Artist:"
                      error={releaseSummary.releaseSubmissionValidity.setup['display_artist_name']}
                      secondaryText={releaseSummary.releaseFormObject.display_artist_name}
                      readonly
                    />
                    <SummaryItem
                      primaryText="Label Line:"
                      error={releaseSummary.releaseSubmissionValidity.setup['display_label_name']}
                      secondaryText={releaseSummary.releaseFormObject.display_label_name}
                      readonly
                    />
                    <SummaryItem
                      primaryText="Genre:"
                      error={releaseSummary.releaseSubmissionValidity.setup['genre']}
                      secondaryText={releaseSummary.releaseFormObject.genre}
                      readonly
                    />
                    <SummaryItem
                      primaryText="Language:"
                      error={releaseSummary.releaseSubmissionValidity.setup['lang']}
                      secondaryText={
                        LANGUAGES2.find(lang => lang.value === releaseSummary.releaseFormObject.lang)?.label ||
                        releaseSummary.releaseFormObject.lang
                      }
                      readonly
                    />
                    <SummaryItem
                      primaryText="Copyright Year:"
                      error={releaseSummary.releaseSubmissionValidity.setup['copyright_year']}
                      secondaryText={releaseSummary.releaseFormObject.copyright_year}
                      readonly
                    />
                    <SummaryItem
                      primaryText="Compilation:"
                      secondaryText={releaseSummary.releaseFormObject._meta.isCompilation ? 'Yes' : 'No'}
                      readonly
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={4}>
                <SummarySectionHeader>
                  <SummarySectionTitle>
                    <TooltipWrapper text={releaseSummary.releaseSubmissionValidity.tracklist.message}>
                      <InfoBadgeWrapper incomplete={releaseSummary.releaseSubmissionValidity.tracklist.errors} />
                    </TooltipWrapper>
                    <HeaderText invalid={releaseSummary.releaseSubmissionValidity.tracklist.errors}>
                      Set Tracklist
                    </HeaderText>
                  </SummarySectionTitle>
                </SummarySectionHeader>
                <Row>
                  <Col xs={12}>
                    <SummaryTracksScrollContainer>
                      {releaseSummary.releaseTracks.map((track, i) => (
                        <div key={i}>
                          <SummaryItem
                            primaryText={track.data.title}
                            error={releaseSummary.releaseSubmissionValidity.tracklist.tracks?.[i]?.message}
                            secondaryText={track.data?.artists.length ? track.data?.artists?.[0]?.full_name : ''}
                            track={track.data}
                            incomplete={releaseSummary.releaseSubmissionValidity.tracklist?.tracks?.[i]?.errors}
                            onClick={() => releaseSummary.releaseSummaryEditClickedHandler('Tracklist')}
                            readonly={!releaseSummary.cardsAccessible['Tracklist']}
                          />
                        </div>
                      ))}
                    </SummaryTracksScrollContainer>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <SummarySectionHeader>
                  <SummarySectionTitle>
                    <InfoBadgeWrapper
                      incomplete={Object.keys(releaseSummary.releaseSubmissionValidity.releaseDetails).length}
                    />
                    <HeaderText invalid={Object.keys(releaseSummary.releaseSubmissionValidity.releaseDetails).length}>
                      Release Details
                    </HeaderText>
                  </SummarySectionTitle>
                </SummarySectionHeader>
                <Row>
                  <Col xs={6}>
                    <SummaryItem
                      primaryText="Live Date:"
                      error={releaseSummary.releaseSubmissionValidity.releaseDetails['release_date']}
                      secondaryText={releaseSummary.releaseFormObject.release_date}
                      onClick={() => releaseSummary.releaseSummaryEditClickedHandler('LiveReleaseDate')}
                      readonly={!releaseSummary.cardsAccessible['LiveReleaseDate']}
                    />
                    <SummaryItem
                      primaryText="Timed Release:"
                      secondaryText={
                        releaseSummary.releaseFormObject._meta.includeReleaseDateTime
                          ? `${DateTime.fromISO(releaseSummary.releaseFormObject.release_date_time, {
                            setZone: true,
                          }).toFormat('h:mma')} Eastern Time`
                          : 'No'
                      }
                      readonly
                    />
                    <SummarySpacer />
                    <SummaryItem
                      primaryText="Platforms:"
                      error={releaseSummary.releaseSubmissionValidity.releaseDetails['dsp_platforms']}
                      secondaryText={
                        releaseSummary.releaseFormObject.dsp_platforms.length < 11
                          ? releaseSummary.releaseFormObject.dsp_platforms
                            .sort()
                            .map(platform => (dsps.find(dsp => dsp.identifier === platform) || {}).name)
                            .join(', ')
                          : `${releaseSummary.releaseFormObject.dsp_platforms?.length || 0} ${pluralize(
                            'Platform',
                            releaseSummary.releaseFormObject.dsp_platforms?.length
                          )}`
                      }
                      onClick={() => releaseSummary.releaseSummaryEditClickedHandler('DeliveryPlatformsAndTerritories')}
                      readonly={!releaseSummary.cardsAccessible['DeliveryPlatformsAndTerritories']}
                    />
                    <SummaryItem
                      readonly
                      error={releaseSummary.releaseSubmissionValidity.releaseDetails['territories']}
                      primaryText="Territories:"
                      secondaryText={`
                        ${
            releaseSummary.releaseFormObject.territories?.length === 1 &&
                          releaseSummary.releaseFormObject.territories[0] === 'Worldwide'
                            ? 'Worldwide'
              : releaseSummary.releaseFormObject.territories.length < 11
                            ? releaseSummary.releaseFormObject.territories
                                .map(territory => (dictTerritories.find(t => t.value === territory) || {}).label)
                  .join(', ')
                            : releaseSummary.releaseFormObject.territories?.length +
                              pluralize(' Territory', releaseSummary.releaseFormObject.territories?.length)
                        }
                      `}
                    />
                  </Col>
                  {releaseSummary.releaseFormObject?._meta?.releaseType !== 'VideoSingle' && (
                    <Col xs={6}>
                      {releaseSummary.releaseFormObject?._meta?.includePreOrder === 'yes' && (
                        <>
                          <SummaryItem
                            primaryText="Pre-order:"
                            secondaryText={releaseSummary.releaseFormObject._meta.includePreOrder ? 'Yes' : 'No'}
                            onClick={() => releaseSummary.releaseSummaryEditClickedHandler('IncludePreOrder')}
                            readonly={!releaseSummary.cardsAccessible['IncludePreOrder']}
                          />

                          {releaseSummary.releaseFormObject.pre_order_release_date && (
                            <SummaryItem
                              primaryText="Pre-order Date:"
                              secondaryText={releaseSummary.releaseFormObject.pre_order_release_date}
                              onClick={() => releaseSummary.releaseSummaryEditClickedHandler('PreOrderDate')}
                              readonly={!releaseSummary.cardsAccessible['PreOrderDate']}
                            />
                          )}

                          {releaseSummary.releaseFormObject?.tracks?.length > 1 && (
                            <SummaryItem
                              primaryText="Instant Grat:"
                              error={releaseSummary.releaseSubmissionValidity.releaseDetails['instant_grat']}
                              secondaryText={
                                summaryInstantGrat.length ? `Tracks: ${summaryInstantGrat.join(', ')}` : 'None'
                              }
                              onClick={() => releaseSummary.releaseSummaryEditClickedHandler('InstantGratTracks')}
                              readonly={!releaseSummary.cardsAccessible['InstantGratTracks']}
                            />
                          )}
                        </>
                      )}
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          )}
        </Modal>
      )}

      {searchISRC && (
        <ChakraModal variant="dark" isOpen={searchISRC.isShowing} onClose={() => hideModal('searchISRC')} size="md">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>What track are you looking for?</ModalHeader>
            <ModalCloseButton />
            <ModalBody flexWrap="wrap" w="100%" alignItems="center">
              Easily add an existing track to this release.
              <TrackSearch
                index="tracks"
                placeholder="Search by ISRC or track name"
                onSelectItem={item => searchISRC.onSelectItem(item)}
              />
            </ModalBody>
            <ModalFooter justifyContent="flex-end" w="100%" mt="1rem">
              <ErrorCol xs={6}>{searchISRC.error || ''}</ErrorCol>
              <ChakraButton
                borderStyle="none"
                loading={searchISRC.status[API_STATUS_KEYS.IN_PROGRESS]}
                onClick={() => hideModal('searchISRC')}>
                Cancel
              </ChakraButton>
            </ModalFooter>
          </ModalContent>
        </ChakraModal>
      )}

      {duplicateRelease && (
        <ChakraModal
          variant="light"
          isOpen={duplicateRelease.isShowing}
          onClose={() => hideModal('duplicateRelease', selectedModalId)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Are you sure you want to duplicate this release?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>You will be able to add and edit all information before submitting for distribution.</ModalBody>
            <ModalFooter>
              <ChakraButton
                variant="small"
                mr=".5rem"
                onClick={() => {
                  hideModal('duplicateRelease');
                }}>
                Cancel
              </ChakraButton>
              <ChakraButton variant="smallDark" onClick={duplicateRelease.onConfirm}>
                Duplicate release
              </ChakraButton>
            </ModalFooter>
          </ModalContent>
        </ChakraModal>
      )}

      {addPayee && (
        <Modal
          header={
            <ModalHeaderWrapper>
              <h4>Create a Payee</h4>
            </ModalHeaderWrapper>
          }
          isShowing={addPayee.isShowing}
          config={{ style: { height: '450px', width: '550px' } }}
          onClose={() => hideModal('addPayee', selectedModalId)}>
          <FormProvider {...addPayee.formMethods}>
            <form
              onSubmit={addPayee.formMethods.handleSubmit(data => addPayee.onCreateClicked(data, addPayee.rowIndex))}
              autoComplete="off">
              <Row>
                <Col xs={12}>
                  <FormGridRow>
                    <Col xs={12}>
                      Payees are anyone that gets paid for a Track Sound Recording Split. Once added, Payees will be
                      invited to join Venice, connect their bank account and start automatically collecting royalties.
                    </Col>
                  </FormGridRow>
                  <FormGridRow>
                    <input
                      type="hidden"
                      name="group"
                      defaultValue={addPayee.groups?.[0]?.value}
                      ref={addPayee.formMethods.register({ required: true })}
                    />
                  </FormGridRow>
                  <FormGridRow>
                    <Col xs={6}>
                      <FieldLabel text="Name" />
                      <TextInput
                        name="name"
                        error={addPayee.formMethods.errors['name']}
                        placeholder="First and Last Name"
                        ref={addPayee.formMethods.register({ required: true })}
                      />
                    </Col>
                    <Col xs={6}>
                      <FieldLabel text="Email Address" />
                      <TextInput
                        name="email"
                        error={addPayee.formMethods.errors['email']}
                        placeholder="Email"
                        ref={addPayee.formMethods.register({
                          required: true,
                          validate: value => value && validator.isEmail(value),
                        })}
                      />
                    </Col>
                  </FormGridRow>
                </Col>
              </Row>
              <Row>
                <ErrorCol xs={6}>{addPayee.status[API_STATUS_KEYS.ERROR] || ''}</ErrorCol>
                <ButtonCol xs={6}>
                  <Button text="Cancel" tertiary onClick={() => hideModal('addPayee', selectedModalId)} />
                  <Button
                    text="Create Payee"
                    submit
                    loading={addPayee.status[API_STATUS_KEYS.IN_PROGRESS]}
                    onClick={addPayee.formMethods.handleSubmit(data => {
                      addPayee.onCreateClicked(data, addPayee.rowIndex);
                    })}
                  />
                </ButtonCol>
              </Row>
            </form>
          </FormProvider>
        </Modal>
      )}

      {releaseDateTooSoon && (
        <ReleaseWarningModal
          isShowing={releaseDateTooSoon.isShowing}
          hideModal={hideModal}
          selectedModalId={selectedModalId}
        />
      )}

      {videoReleaseDateTooSoon && (
        <VideoReleaseWarningModal
          isShowing={videoReleaseDateTooSoon.isShowing}
          hideModal={hideModal}
          selectedModalId={selectedModalId}
        />
      )}

      {confirmIsrc && (
        <ConfirmIsrcModal
          hideModal={hideModal}
          onConfirm={confirmIsrc.onConfirm}
          errorMsg={confirmIsrc.errorMsg}
          isShowing={confirmIsrc.isShowing}
          isrc_code={confirmIsrc.isrc_code}
          splits={confirmIsrc.splits}
        />
      )}

      {confirmSplitChanges && (
        <ConfirmSplitChangesModal
          hideModal={hideModal}
          onConfirm={confirmSplitChanges.onConfirm}
          errorMsg={confirmSplitChanges.errorMsg}
          isShowing={confirmSplitChanges.isShowing}
          releases={confirmSplitChanges.releases}
        />
      )}

      {/* Basic Template */}

      <Modal
        header={
          <ModalHeaderWrapper>
            <h4>A Great Modal</h4>
            <span>Lorem ipsum something some other blah blah ipsum something some other blah blah</span>
          </ModalHeaderWrapper>
        }
        isShowing={{}.isShowing}
        config={{ style: { height: '600px', width: '700px' } }}
        onClose={() => hideModal('X', selectedModalId)}>
        <div>Modal content</div>
      </Modal>
    </ModalsContext.Provider>
  );
};
