import React, { useRef, useEffect } from 'react';
import { withTheme } from '@emotion/react/macro';
import { Col, Row } from 'react-styled-flexboxgrid';
import { withRouter } from 'react-router-dom';
import { SEARCH_RESULT_TYPES } from '../../constants/constants';

import SearchResultSection from '../SearchResultSection/search-result-section';
import ResultsGrid from '../ResultsGrid/results-grid';

import HEAP from '../../constants/HEAP.gen.json';
import useCurrentUser from 'data-client/use-current-user';
import { RiArrowUpSLine } from 'react-icons/ri';
import { useCatalogState, useCatalogQuery } from './use-catalog-state';
import { ClipLoader } from 'react-spinners';
import { useHistory, Link } from 'react-router-dom';
import { Flex, Button, IconButton } from '@chakra-ui/react';

const COLS = 3;
const ROWS = 6;
const PAGE_SIZE = COLS * ROWS;

const displayTestCodeInNonProd = testCode => {
  if (testCode && global.APP.RUNTIME !== 'production') return testCode;
};

const ScrollUpButton = ({ testCode }) => (
  <IconButton
    onClick={() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }}
    data-cy={global.APP.RUNTIME === 'production' ? undefined : displayTestCodeInNonProd(testCode)}
    aria-label="Scroll to Top"
    variant="transparent"
    icon={<RiArrowUpSLine size="2.5rem" />}
  />
);

const SearchResults = ({ match }) => {
  const [dbUser] = useCurrentUser();
  const [catalogState, setCatalogState, onSortChange, handleSetSearch] = useCatalogState(match);
  const releasesQuery = useCatalogQuery(catalogState, setCatalogState, PAGE_SIZE);
  const moreResultsExist = catalogState.hasNextPage && !releasesQuery.isLoading;
  const incrementPageIndex = () => setCatalogState(prevState => prevState.incrementPageIndex());
  const urlTerm = match?.params?.search_term;
  const history = useHistory();

  if (urlTerm && urlTerm !== catalogState.searchTerm) {
    setCatalogState(catalogState.setCurrentSearchTerm(urlTerm));
  }

  const onSearchTermChange = nextSearchTerm => {
    if (nextSearchTerm && urlTerm !== nextSearchTerm) {
      history.push(`/search/releases/${nextSearchTerm}`);
    } else if (!nextSearchTerm && urlTerm) {
      handleSetSearch(false);
      history.push(`/search/releases`);
    } else {
      handleSetSearch(nextSearchTerm);
    }
  };

  const topOfCurrentPageRef = useRef();

  useEffect(() => {
    if (catalogState.currentPage) {
      const top =
        topOfCurrentPageRef.current?.clientHeight * catalogState.pageIndex +
        document.querySelector('header').offsetHeight;
      window.scrollTo({ left: 0, top, behavior: 'smooth' });
    }
  }, [catalogState.currentPage]);

  function getScrollProps(idx) {
    const shouldSetScrollPoint = catalogState.pages.length !== 1 && idx === catalogState.pageIndex;
    return shouldSetScrollPoint ? { ref: topOfCurrentPageRef } : {};
  }

  return (
    <Row>
      {
        <Col xs={12}>
          <SearchResultSection
            id={SEARCH_RESULT_TYPES.RELEASES}
            onSearchTermChange={onSearchTermChange}
            currentSearchTerm={catalogState.searchTerm}
            title={'Catalog'}
            brightTitle={!catalogState.searchTerm}
            sortBy={catalogState.optionsList}
            onSortChange={onSortChange}
            showLoaderView={releasesQuery.isLoading}
            button={
              <Button
                variant="dark"
                as={Link}
                to="/manage"
                pointerEvents={!dbUser?.subscription?.isActive ? 'none' : ''}
                heapCode={HEAP.PUBLIC.COMMON.TYPE.CATALOG_BUTTON_CREATE_RELEASE}
                disabled={!dbUser?.subscription?.isActive}>
                Create Release
              </Button>
            }>
            <>
              {catalogState.pages.map((page, idx) => {
                const scrollProps = getScrollProps(idx);
                return (
                  <div {...scrollProps} key={`grid-page-${idx}`}>
                    <ResultsGrid results={page} pageIdx={idx} />
                  </div>
                );
              })}
              <Flex
                justifyContent="space-between"
                width="100%"
                marginBottom="6rem"
                flexDirection={!moreResultsExist ? 'column' : 'inherit'}>
                {releasesQuery.isLoading && (
                  <Flex alignItems="center" height="100%" justifyContent="center">
                    <ClipLoader />
                  </Flex>
                )}
                {moreResultsExist && !releasesQuery.isLoading && (
                  <Button
                    variant="smallLighter"
                    data-cy={
                      global.APP.RUNTIME === 'production'
                        ? undefined
                        : displayTestCodeInNonProd('results-grid-releases-show-more')
                    }
                    onClick={incrementPageIndex}>
                    Show More
                  </Button>
                )}
                <ScrollUpButton testCode={'results-grid-releases-scroll-to-top'} />
              </Flex>
            </>
          </SearchResultSection>
        </Col>
      }
    </Row>
  );
};

export default withTheme(withRouter(SearchResults));
