import { dataApiUrl } from '../url';
import { DateTime } from 'luxon';
import AuthAxios from '../auth-axios';
import { getTokenSilently } from '../auth0';
import jwt_decode from 'jwt-decode';
import { fetchEventSource, EventStreamContentType, RetriableError, FatalError } from '../fetch-event-source';

const DATA_API_URL = dataApiUrl() + '/co-manager';
const COMMON_HEADER_COLLECTION = new Headers();
COMMON_HEADER_COLLECTION.append('Content-Type', 'application/json');

/**
 * Is the current time within the active support window.
 *
 * @return  {boolean}
 */
export const isActiveSupportWindow = () => {
  // Find out the current time in Pacific Time Zone
  let d = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));

  // Is it between 8am and 5pm?
  return d.getHours() >= 8 && d.getHours() < 17;
};

export const getUserThreads = async ({ page, pageSize }) =>
  AuthAxios.get(DATA_API_URL + '/threads', { params: { page, pageSize } }).then(res => res.data.data);

export const getThreadMessages = async ({ threadId, page, pageSize }) =>
  AuthAxios.get(DATA_API_URL + `/thread/${threadId}/messages`, { params: { page, pageSize } }).then(
    res => res.data.data
  );

export const deleteThread = async threadId => AuthAxios.delete(DATA_API_URL + `/thread/${threadId}`);

/**
 * Send partner's typed message to the server
 *
 * @param {string} userMessage
 * @param {string} [token]
 * @param {number} [rating]
 * @param {string} [guide]
 * @param {string} [threadId]
 * @return {Promise<{ data: { token: string }}>}
 */
export const say = async (userMessage, token, rating, guide, threadId) => {
  return await AuthAxios.post(
    DATA_API_URL + '/say',
    { text: userMessage, token, rating, guide, threadId },
    { headers: COMMON_HEADER_COLLECTION }
  ).then(res => res.data);
};

/**
 * Send partner's input {name, email, chatContent, token} to the server
 *
 * @param {string} [token]
 * @param {string} [email]
 * @param {import('../hooks/use-messages').Message[]} [chatContent]
 * @param {string} [name]
 * @return {Promise<{ data: { token: string }}>}
 */
export const partnerEmail = async (name, email, chatContent, token) => {
  //still need chatContent
  const formattedThread = chatContent.map(m => {
    if (m.type === 'emailCollection') {
      return;
    }
    const shortDate = DateTime.fromMillis(m.createdAt).toLocaleString(DateTime.DATETIME_SHORT);
    return `<br>${m.type === 'ai' ? 'Venice Assistant' : 'You'} (${shortDate})<br> ${m.unfurled_text}<br><br>`;
  });
  return fetch(DATA_API_URL + '/partnerEmail', {
    body: JSON.stringify({ name, email, chatContent: formattedThread, token }),
    method: 'POST',
    mode: 'cors',
    headers: COMMON_HEADER_COLLECTION,
  }).then(res => res.json());
};

/**
 * Listen for server messages. Important to wire up a call to the cancel function when a component is unmounted
 * to avoid memory leaks on the client.
 *
 * @param {string} token - The token from the initial message sent TO the server
 * @param {(data: any) => void} onMessage - Called whenever an incoming message is processed
 * @param {(status: string) => void} onStatusChange - Called whenever the server connection state changes
 * @return {() => void} A function that cancels listening to server events
 */
export const listen = (token, onMessage, onStatusChange) => {
  onStatusChange('Connecting...');

  const ctrl = new AbortController();

  fetchEventSource(DATA_API_URL + `/listen/${token}`, {
    signal: ctrl.signal,
    headers: async () => {
      let token = localStorage.getItem('scale_music_auth0_token');
      const decodedToken = token ? jwt_decode(token) : null;
      const current_time = Date.now().valueOf() / 1000;

      if (!decodedToken || current_time > decodedToken.exp) {
        token = await getTokenSilently();
      }

      return {
        Authorization: `Bearer ${token}`,
        'x-scale-omni-token': localStorage.getItem('scale_music_omni_token') || '',
      };
    },
    async onopen(response) {
      if (response.ok && response.headers.get('content-type').startsWith(EventStreamContentType)) {
        onStatusChange('Connected');
        return; // everything's good
      } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
        onStatusChange('Disconnected - Error, try refreshing the page');
        // client-side errors are usually non-retriable:
        throw new FatalError();
      } else {
        onStatusChange('Failed to connect - Retrying...');
        throw new RetriableError();
      }
    },
    onmessage(msg) {
      // if the server emits an error message, throw an exception
      // so it gets handled by the onerror callback below:
      if (msg.event === 'FatalError') {
        throw new FatalError(JSON.parse(msg.data));
      }
      // Server has asked us to back off and try again later
      if (msg.event === 'backoff') {
        throw new RetriableError(JSON.parse(msg.data));
      }
      onMessage(JSON.parse(msg.data));
    },
    onclose() {
      onStatusChange('Connecting...');
      // if the server closes the connection unexpectedly, retry:
      throw new RetriableError();
    },
    onerror(err) {
      if (err instanceof FatalError) {
        onStatusChange('Disconnected');
        throw err; // rethrow to stop the operation
      } else if (err instanceof RetriableError) {
        onStatusChange('Retrying...');
        return err.retryInterval;
      } else {
        onStatusChange('Retrying...');
        // do nothing to automatically retry. You can also
        // return a specific retry interval here.
      }
    },
  });

  return () => {
    // Cleanup function
    ctrl.abort();
  };
};
