import React, { useMemo } from 'react';
import useCurrentUser from '../data-client/use-current-user';
import membershipConstants from '../constants/MEMBERSHIP.gen.json';

const {
  SUBSCRIPTION_TIER_DISPLAY_NAMES,
  SUBSCRIPTION_TIER_RANK_ORDER,
  SUBSCRIPTION_TIERS: { CORE, ESSENTIALS, GROW_ESSENTIALS, GROW, GROW_PRO, PROFESSIONAL, LABEL, EXECUTIVE },
} = membershipConstants;

const isFunc = thingy => typeof thingy === 'function';

const checkTierRank = (tierToCheck, tierToCheckAgainst, checkAscendingRanks = true) => {
  const tierToCheckIndex = Number(SUBSCRIPTION_TIER_RANK_ORDER[tierToCheck]);
  const tierToCheckAgainstIndex = Number(SUBSCRIPTION_TIER_RANK_ORDER[tierToCheckAgainst]);
  return checkAscendingRanks
    ? tierToCheckIndex >= tierToCheckAgainstIndex
    : tierToCheckIndex <= tierToCheckAgainstIndex;
};

export const buildUserTierChecks = subscription => {
  const tier = subscription?.tier;
  const unknownTier = tier in SUBSCRIPTION_TIER_RANK_ORDER === false;
  const tierDisplayName = SUBSCRIPTION_TIER_DISPLAY_NAMES[tier];

  const isCoreTier = tier === CORE;
  const isEssentialsTier = tier === ESSENTIALS;
  const isGrowEssentialsTier = tier === GROW_ESSENTIALS;
  const isGrowTier = tier === GROW;
  const isGrowProTier = tier === GROW_PRO;
  const isProfessionalTier = tier === PROFESSIONAL;
  const isLabelTier = tier === LABEL;
  const isExecutiveTier = tier === EXECUTIVE;

  const isGrowTierOrBelow = checkTierRank(tier, GROW, false);
  const isGrowTierOrAbove = checkTierRank(tier, GROW);
  const isGrowProTierOrAbove = checkTierRank(tier, GROW_PRO);
  const isGrowProTierOrBelow = checkTierRank(tier, GROW_PRO, false);
  const isEssentialsTierOrAbove = checkTierRank(tier, ESSENTIALS);

  const isInTrial = subscription?.isInTrial;

  //Before the grow tier, essentials members could release atmos. We need to keep the functionality
  //for ONLY the essentials tier users but they cannot release video!
  const canReleaseAtmos = isGrowProTierOrAbove || isEssentialsTier;
  const canReleaseVideo = checkTierRank(tier, GROW_ESSENTIALS);
  const needsRequiredReleaseArtist = isGrowProTierOrBelow;
  const hasLimitedMarketingLinks = isGrowTierOrBelow;
  const canAccessSpotifyForArtists = isEssentialsTier || isGrowEssentialsTier || isGrowTier || isGrowProTier;
  const canAccessMarketingAnalytics = isEssentialsTierOrAbove;
  const canCreateYTShortsPreviews = isGrowProTierOrAbove;
  const canAccessEvents = isGrowTierOrAbove;
  const canAccessCoManager = isGrowProTierOrAbove;

  const makeWithTierFunc =
    useWithFunc =>
      (withFunc = () => {}, withoutFunc = () => {}) =>
        useWithFunc ? withFunc(subscription) : withoutFunc(subscription);

  const withGrowTierOrBelow = makeWithTierFunc(isGrowTierOrBelow);
  const withGrowTierOrAbove = makeWithTierFunc(isGrowTierOrAbove);
  const withGrowProTierOrAbove = makeWithTierFunc(isGrowProTierOrAbove);

  const isTierOrAbove = lowestRequiredTier => checkTierRank(tier, lowestRequiredTier);

  return {
    unknownTier,
    tierDisplayName,
    subscription,
    tier,
    isCoreTier,
    isEssentialsTier,
    isGrowTier,
    isGrowProTier,
    isProfessionalTier,
    isLabelTier,
    isExecutiveTier,
    isGrowTierOrBelow,
    isGrowTierOrAbove,
    isGrowProTierOrAbove,
    isGrowProTierOrBelow,
    isEssentialsTierOrAbove,
    isInTrial,

    canReleaseAtmos,
    canReleaseVideo,
    needsRequiredReleaseArtist,
    hasLimitedMarketingLinks,
    canAccessSpotifyForArtists,
    canAccessEvents,
    canAccessMarketingAnalytics,
    canCreateYTShortsPreviews,
    canAccessCoManager,
    withGrowTierOrBelow,
    withGrowTierOrAbove,
    withGrowProTierOrAbove,
    isTierOrAbove,
  };
};

export const WithSysAdmin = (callback, defaultCallback) => {
  const [currentUser] = useCurrentUser();
  const returnThingy = currentUser.system_admin ? defaultCallback : callback;
  return isFunc(returnThingy) ? returnThingy(currentUser) : returnThingy;
};

export const useUserTierChecks = () => {
  const [currentUser] = useCurrentUser();
  const userTierChecks = useMemo(() => buildUserTierChecks(currentUser?.subscription), [currentUser]);

  return userTierChecks;
};

export const withTiers =
  (...tiers) =>
    (callback, defaultCallback) => {
      const [currentUser] = useCurrentUser();
      const returnThingy = tiers.includes(currentUser?.subscription?.tier) ? callback : defaultCallback;
      return isFunc(returnThingy) ? returnThingy(currentUser) : returnThingy;
    };

export const renderWithTiers = (...tiers) => {
  return (Component, DefaultComponent) => {
    const [currentUser] = useCurrentUser();
    const RenderedComponent = tiers.includes(currentUser?.subscription?.tier) ? Component : DefaultComponent;

    if (RenderedComponent) {
      return (...props) => <RenderedComponent {...props} />;
    } else {
      return () => null;
    }
  };
};

export const renderWithEssentialsTier = renderWithTiers(ESSENTIALS);
