import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Heading, Text, useStyleConfig } from '@chakra-ui/react';
import INTERVAL from '../../constants/INTERVAL.gen.json';

export function TierCard(props) {
  const { tier, onClick, isSelected, variant, ...rest } = props;
  const { price, couponPrice, title, description, tierName, interval, trial, trialPrice } = tier;
  const isClickable = typeof onClick === 'function';
  const isCouponApplicable = !!couponPrice && couponPrice !== price.toString();
  const isTrialPriceApplicable = trial && trialPrice !== '0.00' && trialPrice !== undefined;
  const styles = useStyleConfig('TierCard', { variant });
  const intervalLabel = INTERVAL[interval].short ?? 'YEAR';

  const cardStyles = useMemo(() => {
    const _selected = Object.assign(
      {},
      {
        border: `2px solid`,
        borderColor: 'pink',
      },
      styles?.['_selected'] ?? {}
    );

    return { ...styles, ...(isSelected && _selected) };
  }, [styles, isSelected]);

  return (
    <Box
      __css={cardStyles}
      {...rest}
      p="0.75rem 1rem 1rem"
      mt="1rem"
      onClick={isClickable ? () => onClick(tierName, interval) : () => {}}
      cursor={isClickable ? 'pointer' : 'default'}
      data-cy={global.APP.RUNTIME === 'production' ? undefined : `tier-card-${tier.tierName}`}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column" justifyContent="end">
          <Heading fontSize="24px">{title}</Heading>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="end" alignItems="end" pl="4rem">
          <Text height={'1rem'} fontSize={'0.8rem'} variant="boldGray">
            PRICE
          </Text>
          <Box margin={0} padding={0} fontWeight={900} letterSpacing="0.5px">
            {isCouponApplicable && (
              <Text color="pink" mr="0.25rem" as="span">
                $
                {couponPrice
                  .split('.')
                  .filter(s => s !== '00')
                  .join('.')}
              </Text>
            )}
            {isTrialPriceApplicable ? (
              <Text as={isCouponApplicable ? 's' : null} whiteSpace="nowrap">
                ${trialPrice}
              </Text>
            ) : (
              <Text as={isCouponApplicable ? 's' : null} whiteSpace="nowrap">
                ${price}/{intervalLabel.toUpperCase()}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="start" fontSize="0.875rem" lineHeight="1.5rem" mt="1.5rem">
        <ReactMarkdown>{description}</ReactMarkdown>
      </Box>
    </Box>
  );
}
